import { DropDownOption } from "./dropdown";
import { ModelCount, ParameterValue } from "./ModelInfo";
import { FilterValue } from "./filters";
import { IFramePopupData, PopupActionData } from "../types/popup";
import { VehicleConditionalCardData } from "./blocks";
import { Vehicle } from "./vehicle";

export enum PropertyStyles {
  TEXT = "text",
  TEXT_WITH_DISCLAIMER = "text_with_disclaimer",
  STRIKEOUT_TEXT = "strikeout_text",
  INVERTED = "inverted",
}

export enum PropertyData {
  PROPERTY_VALUE = "property_value",
  DIFFERENCE = "difference",
  SEPARATOR = "separator",
  SLASH = "slash",
}

export enum PropertyVisualStyle {
  PLAIN_TEXT = "plain_text",
  PLAIN_PRICE = "plain_price",
  STRIKEOUT_PRICE = "strikeout_price",
  DISCOUNT = "discount",
  PRELIMINARY_PRICE = "preliminary_price",
  FINAL_PRICE = "final_price",
  INVERTED = "inverted",
}

export enum ShowIfNotSetValues {
  NOTHING = "nothing",
  CALL_TO_PRICE = "call_to_price",
  LOCKED = "locked",
  ALWAYS = "always",
}

export interface VehicleProperty {
  name: string;
  title: string;
  internalProperties?: string[];
  disclaimer?: string;
  data: PropertyData;
  visualStyle: PropertyVisualStyle;
  showIfNotSet?: ShowIfNotSetValues;
  lockText?: string;
  lockTextLeftGradientColor?: string;
  lockTextRightGradientColor?: string;
}

export interface VehicleTab {
  title: string;
  properties?: VehicleProperty[];
}

export enum ScrollTypeNew {
  PAGINATION_TOP_BOTTOM = "PAGINATION_TOP_BOTTOM",
  PAGINATION_BOTTOM = "PAGINATION_BOTTOM",
  INFINITE_SCROLL = "INFINITE_SCROLL",
}

export enum RouteType {
  NEW_WINDOW = "new_window",
  SAME_WINDOW = "same_window",
  POPUP = "popup",
}

export interface BaseAction extends PopupActionData {
  path?: string;
  routeType?: RouteType;
}

export enum PrefetchVehicleDataInputFormat {
  JSON = "json",
  CSV = "csv",
}

export interface HeaderData {
  header: string;
  value: string;
}

export interface PrefetchData {
  url: string;
  fields: string[];
  fieldSeparator?: string;
  vehicleSeparator?: string;
  method: string;
  format: PrefetchVehicleDataInputFormat;
  headers: HeaderData[];
}

export enum ActionButtonPosition {
  BOTTOM = "bottom",
  UNDER_IMAGES = "under_images",
}

export interface ActionButtonData extends BaseAction {
  id: string;
  buttonName: string;
  dynamic: boolean;
  scriptData?: string;
  cssData?: string;
  layout: string;
  reducedLayout?: string;
  actionButtonData?: any;
  prefetchData?: PrefetchData;
  externalScript?: ScriptData;
  popupData?: IFramePopupData;
  position: ActionButtonPosition;
}

export interface SingleVehicleCardConfig {
  tabs: VehicleTab[];
  priceStyle?: PropertyStyles;
}

export enum VehicleCardStyle {
  GRID = "grid",
  ROWS = "rows",
}

export interface BaseMenuItem extends BaseAction {
  id: string;
  title: string;
}

export interface SubmenuItem extends BaseMenuItem {
  image?: string;
  buttonMode: boolean;
}

export interface ButtonAction extends BaseAction {
  title: string;
}

export interface LinkData {
  title: string;
  path: string;
}

export enum SpecialMenuType {
  NEW_MODELS = "new_models",
}

export interface MenuItem extends BaseMenuItem {
  submenu: SubmenuItem[];
  specialMenu?: SpecialMenuType;
}

export interface MainMenu {
  items: MenuItem[];
}

export interface GeoLocation {
  longitude: number;
  latitide: number;
}

export interface Address {
  address: string;
  city: string;
  state: string;
  zip: string;
}

export interface TimePeriod {
  startTime: number;
  endTime: number;
}

export interface ScheduleItem {
  dayOfWeek: number;
  workingTime: TimePeriod;
}

export interface Schedule {
  workDays: ScheduleItem[];
}

export interface SocialMedia {
  type: string;
  url: string;
}

export interface DealerDepartmentBaseInfo {
  title: string;
  phone: string;
}

export interface DealerDepartment extends DealerDepartmentBaseInfo {
  schedule: Schedule;
}

export const SALES_DEPARTMENT_INDEX = 0;
export const SERVICE_DEPARTMENT_INDEX = 1;

export interface IDealerInfo {
  id: string;
  name: string;
  departments: DealerDepartment[];
  address: Address;
  location: GeoLocation;
  socialMedias: SocialMedia[];
}

export interface PopupConfig {
  leadFormsShowDetailPanel: boolean;
  contactOptions: DropDownOption[];
}

export interface SearchCategory {
  title: string;
  path: string;
}

export interface Search {
  popularCategories: SearchCategory[];
}

export interface Attribute {
  name: string;
  value: string;
}

export enum ScriptUsage {
  PAGES_ONLY = "pages_only",
  ALL = "all",
}

export interface ScriptData {
  id: string;
  url?: string;
  content?: string;
  async?: boolean;
  lazy: boolean;
  customAttributes?: Attribute[];
  usage: ScriptUsage;
}

export interface MetaData {
  name: string;
  content: string;
}

export interface CustomPhoneData {
  title: string;
  phone: string;
}

export enum LogoPosition {
  CENTER = "center",
  LEFT = "left",
}

export enum MenuOrientation {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export interface VisualSiteConfig {
  fontFiles: string[];
  themeCss: string;
  logoPosition?: LogoPosition;
  menuOrientation?: MenuOrientation;
}

export interface ClientSiteData {
  id: string;
  siteConfigId: string;
  dealerInfos: IDealerInfo[];
  newModels: ModelCount[];
  baseUrl: string;
  popupConfig: PopupConfig;
  noImagesPhoto?: string;
  siteForAutoTests?: boolean;
  customPhones?: CustomPhoneData[];
}

export interface SiteData {
  id: string;
  siteConfigId: string;
  visualConfig: VisualSiteConfig;
  menu: MainMenu;
  footerMenu?: MainMenu;
  dealerInfos: IDealerInfo[];
  privacyUrl: string;
  termsAndConditionsUrl: string;
  logo: string;
  allowedForRobot: boolean;
  siteName: string;
  url: string;
  siteTitleTemplate: string;
  ogImage?: string;
  newModels: ModelCount[];
  faveicon?: string;
  baseUrl: string;
  popupConfig: PopupConfig;
  showAccessibility: boolean;
  search: Search;
  noImagesPhoto?: string;
  scripts: ScriptData[];
  cmsUrl?: string;
  metas?: MetaData[];
  needCookiesBanner: boolean;
  customPhones?: CustomPhoneData[];
  customPhonesCaption?: string;
  fillAscDataLayer?: boolean;
  siteForAutoTests?: boolean;
  showLanguageSwitcher?: boolean;
}

export enum PageType {
  CONTENT = "content",
  SRP = "srp",
  VDP = "vdp",
  FAVORITES = "favorites",
  COMPARE = "compare",
  IFRAME_POPUP = "iframe_popup",
  RAW_DATA = "raw-data",
}

export interface PageData {
  id: string;
  pageType: PageType;
  pagePath: string;
  pageName: string;
  pageTitleFormat: string;
  pageDescriptionFormat: string;
  keywords?: string;
  blocks?: string[];
  ogImage: string;
  rawDataUrl?: string;
  scripts?: ScriptData[];
  ogTitle?: string;
  ogDescription?: string;
}

export interface QAItemData {
  question: string;
  answer: string;
}

export interface QAData {
  title: string;
  questions: QAItemData[];
}

export interface BlockData {
  componentName: string;
  data: any;
  preconnectUrls?: string[];
}

export interface PageDescription {
  path: string;
  lastModified: string;
}

export interface CustomGalleriaItem {
  title: string;
  resolverUrl: string;
  vehicleParams: string[];
  otherParams?: ParameterValue[];
}

export interface ConditionalPopup {
  condition: FilterValue[];
  pathToPopup: string;
}

const numberProperties = ["price", "mileage", "msrp", "year", "miscPrice"];

export function findVehicleCardData(
  vehicle: Vehicle,
  vehicleCardsData: VehicleConditionalCardData[],
): VehicleConditionalCardData | undefined {
  for (let i = 0; i < vehicleCardsData.length; i++) {
    const cardData = vehicleCardsData[i];
    if (isVehicleMatchCondition(vehicle, cardData.condition)) {
      return cardData;
    }
  }
}

export function isVehicleMatchCondition(vehicle: any, conditions: FilterValue[]): boolean {
  let found = true;
  conditions.forEach((condition) => {
    if (numberProperties.includes(condition.filter)) {
      const strValues = condition.value.split("-");
      if (strValues.length === 1) {
        if (vehicle[condition.filter] !== parseFloat(strValues[0])) {
          found = false;
        }
      } else if (strValues.length === 2) {
        if (
          vehicle[condition.filter] < parseFloat(strValues[0]) ||
          vehicle[condition.filter] > parseFloat(strValues[1])
        ) {
          found = false;
        }
      } else {
        found = false;
      }
    } else {
      const strValues = condition.value.split(",");
      if (!strValues.includes(vehicle[condition.filter])) {
        found = false;
      }
    }
  });
  return found;
}
