import { ColorType } from "./colors";
import { NamedProperty } from "./filters";

export enum VehicleType {
  NEW = "NEW",
  USED = "USED",
  CERTIFIED = "CERTIFIED",
  LOANER = "LOANER",
}

export enum TransitStatus {
  IN_STOCK = "in_stock",
  IN_TRANSIT = "in_transit",
  ON_ORDER = "on_order",
}

export class Vehicle {
  id!: string;
  type!: VehicleType;
  year!: number;
  make!: string;
  model!: string;
  trim!: string;
  body!: string;
  drivetrain!: string;
  transmission!: string;
  color?: ColorType;
  colorInternal?: ColorType;
  extColor!: string;
  intColor!: string;
  engine!: string;
  cityMPG?: number;
  highwayMPG?: number;
  fuelType?: string;
  stock!: string;
  vin!: string;
  modelCode!: string;
  mileage!: number;
  lotDate!: Date;
  description!: string;
  features!: string[];
  popularFeatures!: NamedProperty[];
  photos!: string[];
  photosBig?: string[];
  photosMedium?: string[];
  photosSmall?: string[];
  msrp!: number;
  price!: number;
  invoice!: number;
  miscPrice?: number;
  accessories!: number;
  dealer!: string;
  finalPrice?: number;
  creditPaymentPerMonth?: number;
  actionButtonsData?: any[];
  transitStatus?: TransitStatus;
}

export interface VehiclesResponse {
  totalCount: number;
  vehicles: Vehicle[];
}

export interface SearchResponse {
  vehicles: Vehicle[];
}

export interface FieldPropertyValueReduced {
  v: string; // value of field
  p: number; // priority of the field
}

export interface VehicleFieldPriorityReduced {
  f: string; // field
  v: FieldPropertyValueReduced[]; // priority values for this field
}
