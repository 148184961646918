import { defaultLocale } from "../locales";
import { Address, TimePeriod } from "../types/site";
import { VehicleType } from "../types/vehicle";
import { getStateAbbr } from "../utils/getStateAbbr";

function strTime(seconds: number) {
  let hours = Math.trunc(seconds / 3600);
  let minutes = (seconds - hours * 3600) / 60;
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;

  hours = hours ? hours : 12;
  const formatMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${formatMinutes} ${ampm}`;
}

export function formatPhone(phone: string): string {
  if (!phone) {
    return phone;
  }
  const code = phone.substring(0, 3);
  const part1 = phone.substring(3, 6);
  const lastPart = phone.substring(6);
  return `(${code}) ${part1}-${lastPart}`;
}

export function formatNumber(num: number | string): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatTimePeriod(timePeriod: TimePeriod): string {
  return strTime(timePeriod.startTime) + " - " + strTime(timePeriod.endTime);
}

export function formatAddress(address?: Address): string {
  return address ? `${address?.address}, ${address?.city}, ${getStateAbbr(address?.state)} ${address?.zip}` : "";
}

export function formatDate(date?: string): string {
  return date ? new Date(date).toLocaleDateString(defaultLocale, { timeZone: "UTC" }) : "";
}

export function parseDate(date: string): string {
  const parts = date.split("/");
  const result = new Date(Date.UTC(parseInt(parts[2], 10), parseInt(parts[0], 10) - 1, parseInt(parts[1], 10)));
  return result.toISOString();
}

export function smartFormat(str: string, tokens: any) {
  let formatted = str;
  const tokenKeys = Object.keys(tokens);
  for (let i = 0; i < tokenKeys.length; i++) {
    formatted = formatted.replace(RegExp(`{${tokenKeys[i]}}`, "g"), tokens[tokenKeys[i]]);
  }

  return formatted;
}

export function convertVehicleType(type: VehicleType): string {
  switch (type) {
    case VehicleType.USED:
      return "Used";
    case VehicleType.CERTIFIED:
      return "Certified";
    case VehicleType.NEW:
      return "New";
    case VehicleType.LOANER:
      return "Loaner";
    default:
      console.error(`Unknown vehicle type ${type}`);
      return type;
  }
}

export function formatFileSize(fileSize: number): string {
  if (fileSize < 10 * 1024) {
    return formatNumber(fileSize) + " B";
  } else if (fileSize < 1024 * 1024) {
    return formatNumber(fileSize / 1024) + " KB";
  } else {
    return formatNumber(fileSize / (1024 * 1024)) + " MB";
  }
}
